import React from 'react'
import NavBar from '../components/navbar/NavBar'
import HeartRate from './heart-rate/HeartRate'

const Projects = () => {
  return (
    <div id="projects">
      <NavBar/>
      <HeartRate/>
    </div>
  )
}

export default Projects